import GATSBY_COMPILED_MDX from "/home/runner/work/jcduran-gatsby/jcduran-gatsby/_projects/project/ec-product-page.mdx";
import React from 'react';
import {graphql} from "gatsby";
import {GatsbyImage, getImage} from "gatsby-plugin-image";
import Layout from '../components/Layout';
import Seo from '../components/SEO';
function ProjectLayout({data, children}) {
  const project = data.mdx.frontmatter;
  const image = getImage(data.mdx.frontmatter.mainImage);
  const projectExcerpt = data.mdx.excerpt;
  const role = project.role.map((r, idx) => {
    return React.createElement("li", {
      key: idx
    }, r);
  });
  const technology = project.technology.map((r, idx) => {
    return React.createElement("li", {
      key: idx
    }, r);
  });
  return React.createElement(Layout, null, React.createElement(Seo, {
    title: `Juan Duran | ${project.title} Project`,
    desc: projectExcerpt
  }), React.createElement("div", {
    className: "relative flex flex-col items-center justify-center"
  }, React.createElement("div", {
    className: "container mt-10"
  }, React.createElement("div", {
    className: "grid lg:grid-cols-2 md:grid-cols-2 sm:grid-cols-1 mb-5 gap-x-8 gap-y-4 mx-5 lg:mx-9"
  }, React.createElement("div", null, React.createElement(GatsbyImage, {
    image: image,
    alt: ""
  })), React.createElement("div", null, React.createElement("div", {
    className: "flex flex-col"
  }, React.createElement("div", {
    className: "text-center md:text-left"
  }, React.createElement("h1", {
    className: "mb-8 text-3xl"
  }, project.title)), React.createElement("div", {
    className: "mb-10 text-center md:text-left"
  }, children), React.createElement("div", {
    className: "mb-5 text-center md:text-left"
  }, React.createElement("h3", {
    className: "uppercase font-bold"
  }, "Role"), React.createElement("ul", null, role)), React.createElement("div", {
    className: "mb-5 text-center md:text-left"
  }, React.createElement("h3", {
    className: "uppercase font-bold"
  }, "Technology"), React.createElement("ul", null, technology)), project.repo && React.createElement("div", {
    className: "mt-5 mb-5 text-center md:text-left"
  }, React.createElement("a", {
    href: project.repo,
    className: "bg-transparent hover:bg-black text-black hover:text-white py-2 px-6 border border-black hover:border-black ease-in duration-200"
  }, " Visit Repo"))))))));
}
export const pageQuery = graphql`
query ($slug: String!) {
  mdx(fields: { slug: { eq: $slug } }) {
    excerpt
    fields {
      slug
    }
    frontmatter {
      title
      mainImage {
        childImageSharp {
          gatsbyImageData(
            formats: [AUTO, WEBP, AVIF]
          )
        }
      } 
      role
      technology
      repo
    }
  }
}
`;
ProjectLayout
export default function GatsbyMDXWrapper(props) {
  return React.createElement(ProjectLayout, props, React.createElement(GATSBY_COMPILED_MDX, props));
}
